import React from 'react'
import { graphql } from 'gatsby'
import { Container, Col, Row } from 'reactstrap'
import Img from "gatsby-image"

import SiteProductVendor from '../../../components/SiteProductVendor'
import SEO from '../../../components/seo'

class hydroRain extends React.Component {
  render() {
    const path = this.props.location.pathname
    const vendor = path && path.split('/')[2]
    const logo = this.props.data.allImageSharp.edges.find(e => e.node.fluid.originalName.split('.')[0] === vendor)?.node.fluid
    const vendorName = vendor.charAt(0).toUpperCase() + vendor.slice(1);
    const seoData = {
      title: `Produits d'irrigation ${vendorName}`,
      description: `Consultez notre liste de produits d'irrigation ${vendorName}`,
      coverImage: logo,
      path: path,
      schemaOrg: `{"@context": "http://schema.org","@type": "Product", "name": "${vendorName}", "Brand": "${vendorName}", "image": "${logo}", "description": "Consultez notre liste de produits irrigation ${vendorName}"}`
    }
    
    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <Row style={{alignItems: "center"}}>
            <Col lg={2} md={2} xs={6}>
              <Img fluid={logo} alt={vendor.toUpperCase()} />
            </Col>
            <Col lg={10} md={10} xs={12}>
            <h1 style={{margin: 0, marginTop: 10}}>Produits d'irrigation {vendorName}</h1>
            </Col>
          </Row>
          <SiteProductVendor {...this.props} />
        </Container>
      </div>
    )
  }
}

export default hydroRain

export const hydrorainPageQuery = graphql`
  query HydroRainProducts {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    remark: allMarkdownRemark(
      filter: {
        frontmatter: { vendor: { eq: "HydroRain" }, layout: { eq: "product" } }
      }
    ) {
      products: edges {
        product: node {
          id
          html
          frontmatter {
            layout
            title
            path
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "hydrorain.png"
    ]}}}) {
      edges {
        node {
          fluid(maxWidth: 400) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
